import React, { useRef } from "react";
import "./weeklyReports.css";

const WeeklyReports = () => {
  const fileInputRef = useRef(null);

  const handleFileClick = () => {
    fileInputRef.current.click();
  };
  return (
    <div className="weeklyReportsCont">
      <div className="weekCardcont">
        <h1>Week - 1</h1>

        <button
          disabled
          type="submit"
          style={{ cursor: "not-allowed" }}
          className="btnClass"
        >
          Submited
        </button>
      </div>
      <div className="weekCardcont">
        <h1>Week - 2</h1>

        <button
          disabled
          type="submit"
          style={{ cursor: "not-allowed" }}
          className="btnClass"
        >
          Submitted
        </button>

        <input type="file" ref={fileInputRef} className="fileinput" />
      </div>
      <div className="weekCardcont">
        <h1>Week - 3</h1>

        <button
          disabled
          type="submit"
          style={{ cursor: "not-allowed" }}
          className="btnClass"
        >
          Submitted
        </button>
      </div>
      <div className="weekCardcont">
        <h1>Week - 4</h1>

        <button className="uploadfileBtn" onClick={handleFileClick}>
          upload file
        </button>
        <input type="file" ref={fileInputRef} className="fileinput" />
        <textarea
          name=""
          id=""
          cols="30"
          rows="10"
          placeholder="Enter comments"
        >
          {" "}
        </textarea>

        <button className="btnClass">Submit</button>
      </div>
      <p>
        Note: Please ensure that you write the report yourself without the use
        of any AI agents or assistance.
      </p>
    </div>
  );
};

export default WeeklyReports;
