import React from "react";
import { taskDetails } from "../../data";

const MyTasks = () => {
  return (
    <div>
      <h2>My Tasks</h2>
      <table
        style={{
          border: "1px solid black",
          borderCollapse: "collapse",
          width: "100%",
        }}
      >
        <thead>
          <tr>
            <th style={{ border: "1px solid black", padding: "18px" }}>
            Task Name
            </th>
            <th style={{ border: "1px solid black" }}>Task Deadline</th>
            <th style={{ border: "1px solid black" }}>Task Priority</th>
            <th style={{ border: "1px solid black" }}>Task Description</th>
          </tr>
        </thead>

        {taskDetails.map((task) => (
          <tbody key={task.id}>
            <tr>
              <td style={{ border: "1px solid black", padding: "12px" }}>
                {task.name}
              </td>
              <td style={{ border: "1px solid black" }}>{task.deadLine}</td>
              <td style={{ border: "1px solid black" }}>{task.priority}</td>
              <td style={{ border: "1px solid black" }}>{task.description}</td>
            </tr>
          </tbody>
        ))}
      </table>
    </div>
  );
};

export default MyTasks;
