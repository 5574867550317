

import React from 'react'

const Notfound = () => {
  return (
    <div>
      <div>
     <p  style={{textAlign:"center",marginBottom:"15px",fontSize:"25px"}}>Sorry Page Was Not Found</p>
        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTY6olTvHV6AUFAsn6Uc8pf6PZ-UfgJAptTfw&usqp=CAU" height={500} alt="notfound"/>
 </div>
    </div>
  )
}

export default Notfound
