import React,{useRef} from "react";
import "./upcomingDeadLines.css";

const UpcomingDeadLines = () => {
  const fileInputRef = useRef(null);

  const handleFileClick = () => {
    fileInputRef.current.click();
  };
  return (
    <div className="upcomingContainer">
      <div className="upcomingSubContainer">
        <div className="upcomingCard">
          <h3>Task Name</h3>
          <h3>Task Deadline</h3>
        </div>

        <div className="uploadContainer">
        <button className="uploadfileBtn" onClick={handleFileClick}>
          upload file
        </button>
        <input type="file" ref={fileInputRef} className="fileinput" />
        <textarea
          type="text"
          rows={5}
          placeholder="Enter Comment (Optional)"
          className="inputStylesTextArea"
        />
      </div>      
      <div className="btnContainer">
      <button className="btnClass">Submit</button>
    </div>
    </div>
    <div className="upcomingSubContainer">
    <div className="upcomingCard">
      <h3>Task Name</h3>
      <h3>Task Deadline</h3>
    </div>

    <div className="uploadContainer">
    <button className="uploadfileBtn" onClick={handleFileClick}>
      upload file
    </button>
    <input type="file" ref={fileInputRef} className="fileinput" />
    <textarea
      type="text"
      rows={5}
      placeholder="Enter Comment (Optional)"
      className="inputStylesTextArea"
    />
  </div>      
  <div className="btnContainer">
  <button className="btnClass">Submit</button>
</div>
</div>
     
    </div>
  );
};

export default UpcomingDeadLines;
