import './App.css';
import Register from './pages/Register/register';
import Login from './pages/Login/login';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MyTasks from './pages/MyTasks/mytasks';
import LayoutComponent from './pages/LayoutComponent/layoutComponent';
import UpcomingDeadLines from './pages/UpComingDeadLines/upcomingDeadLines';
import WeeklyReports from './pages/WeeklyReports/weeklyReports';
import Message from './pages/Messages/messages';
import Notfound from './components/notFound/notfound';
import ProtectedRoute from './components/protectedRoute/protectedRoute';



function App() {
  return (
    <div className="App">
    
    <Router>
    <Routes>
   
        <Route path="/" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route element={<ProtectedRoute />}>
        <Route path="/" element={<LayoutComponent />}>
        <Route path="/tasks" element={<MyTasks />}/>
        <Route path="/deadlines" element={<UpcomingDeadLines />}/>
        <Route path="/weekly_reports" element={<WeeklyReports />}/>
        <Route path="messages" element={<Message/>} />
        </Route>

      </Route>
              <Route path="*" element={<Notfound/>} />
    </Routes>

  </Router>    </div>
  );
}

export default App;
