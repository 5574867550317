


export const chats = [
    {
        id: 1,
        img: "/logo192.png",
        name: "Renuka",
        text: "Welcome to Whatsapp",
        date: Date.now(),
    },
    {
        id: 2,
        img: "/logo192.png",
        name: "Preethi",
        textid: "read",
        text: "Hii Manikanta",
        date: Date.now(),
    },
   
   


];


export const taskDetails = [
    {
      id: 1,
      name: "Login Page",
      deadLine: "2 days",
      priority: "High",
      description:
        "Create a Simple Login Page With Your Username And Password"
    },
    {
      id: 2,
      name: "SignIn Page",
      deadLine: "2 days",
      priority: "High",
      description:
        "Create a Simple Signin Page With Your Email And Username And Password"
    },
   
  ];
  
